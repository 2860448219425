<template>
  <div>
    <div>
      <!-- <home-menu-header v-if="branch" :menu="menu" :branch="branch" :branchs="branchs" /> -->
      <img src="/img/home/HomeHeader.jpeg" style="width: 100%;" />
      <!-- <home-offers v-if="offers" :offers="offers"></home-offers> -->
      <div v-if="false" class="">
        <div class="">
          <p></p>
          <!-- slider -->
          <div v-if="offers" class="trending-slider rounded">
            <div v-for="offer in offers" v-bind:key="offer.id" class="osahan-slider-item">
              <div class="
                  list-card
                  bg-white
                  h-100
                  rounded
                  overflow-hidden
                  position-relative
                  shadow-sm
                ">
                <div class="list-card-image">
                  <a>
                    <img :src="$store.state.baseURL + '/' + offer.image" class="img-fluid item-img w-100" />
                  </a>
                </div>
                <div class="p-3 position-relative">
                  <div class="list-card-body">
                    <h6 class="mb-1">
                      <a href="checkout" class="text-black">{{
                        offer.name_en
                      }}</a>
                    </h6>
                    <p class="text-gray mb-3">{{ offer.description_en }}</p>
                    <p v-for="size in offer.info" v-bind:key="size.id">
                      {{ size.size_en }}
                      <span class="text-gray m-0">
                        <span class="text-black-50">{{
                          size.price.price
                        }}</span>

                        <span v-on:click="getExtras(offer.id, size.id)"
                          class="btn btn-outline-secondary btn-sm small m-2" data-toggle="modal"
                          data-target="#extras">ADD</span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- checkout check details -->
      <div class="d-none d-md-none d-lg-block d-xl-block p-0">
        <div onclick="$(`#side_shopping_card`).toggle()" class="btn btn-sm float-right" style="position: absolute;
              margin-top: -45px;
              right: 0px;
              z-index: 1;">
          <i class="feather-sidebar" style="font-size: 2rem;color: #663435;"></i>
        </div>

        <div
          style="position: fixed;top:0px;right: 0px;z-index: 2;display: none;overflow-y: auto;height: -webkit-fill-available;"
          id="side_shopping_card" class="overflow-auto p-0 bg-light">

          <div style="height: 30px;
                    position: sticky;
                    top: 0px;
                    background-color: white;
                    z-index: 2;
                    padding: 2px;
                    text-align: left;
                    padding-left: 10px;box-shadow: 0px 0px 3px black;">
            <span>
              <i style="background-color: black !important;margin-right: 0px !important;"
                class="header-icon cart-icon h6 mr-2 mb-0"></i>
              <span style="font-family: steelfish;
    font-size: 1.8vw;
    letter-spacing: 2px;
    font-variant: all-petite-caps;
    font-weight: bold;
    line-height: normal;"> Shopping Cart</span></span>
            <a onclick="$(this).parent().parent().hide()" role="button" class="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
          <checkout-details v-if="order && branchs" :order="order" :actionBtnMethod="'checkout'" :branch="branch"
            :TableNumber="$route.params.Table" :RoomNumber="$route.params.Room" />
        </div>
      </div>
      <!-- menu -->
      <div v-if="menu">
        <!-- Favorites -->
        <div class="container" style="margin-bottom: 30px">
          <div class="col-12 pt-3">
            <div class="home-section-title pb-3">
              OUR FAVORITE PICKS
            </div>
            <div v-for="section in menu.sections.slice(0, 1)" v-bind:key="section.id"
              class="row justify-content-center">
              <div v-for="section in menu.sections" v-bind:key="section.id" class="row justify-content-center">
                <div v-show="item.sizes.length > 0"
                  v-for="item in section.items.filter((fav) => { return (fav.sizes[0].id == 49 || fav.sizes[0].id == 53 || fav.sizes[0].id == 56) })"
                  v-bind:key="item.id" class="p-2 pb-4 col-3">
                  <div class="row text-center align-top">
                    <div class="col-12 text-right">
                      <a v-on:click="getExtras(item.id, item.sizes[0].id)" href="#"
                        class="d-inline btn btn-primary btn-sm small m-0 p-0 rounded-circle"
                        style="color:#fff !important; font-family: 'Arial'; line-height: 0px;  font-size: 110% !important;font-weight: bolder; padding: 3px 7px !important;"
                        data-toggle="modal" data-target="#extras">+</a>
                    </div>
                    <div class="col-12 text-center p-2" style="vertical-align: middle;margin-top:-35px;z-index: -1;">
                      <img style="width: 100%;height:100%" alt="#" :src="$store.state.baseURL + '/' + item.image"
                        class="p-1 rounded-circle" />
                    </div>
                  </div>
                  <div class="w-100">
                    <!-- <div v-for="size in item.sizes" v-bind:key="size.id" class="col-4"> -->
                    <div v-if="item.sizes[0] && item.sizes[0].price" class="text-muted mb-0 w-100 text-center">
                      <div style="font-size: 1.2rem;">
                        <div style="font-family: WondarQuason;">{{ item.sizes[0].size_en }}</div>
                        <div style="font-family: Tahoma;color: #663435;">{{ currency + " " + item.sizes[0].price.price
                          }}
                        </div>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Menu -->
        <i ref="scrollToMe"></i>
        <br>
        <hr style="border-color: black;border-width: 3px;">
        <div class="home-section-title">
          MADE FROM SCRATCH!
          <div>CHECK OUT OUR PLATES</div>
        </div>
        <hr style="border-color: black;border-width: 3px;">

        <div class="container position-relative">
          <div class="row" style="margin-bottom: 30px">
            <div class="col-12 pt-3">
              <div v-for="section in menu.sections" v-bind:key="section.id" class="row justify-content-center">
                <div v-show="item.sizes.length > 0" v-for="item in section.items" v-bind:key="item.id"
                  class="p-2 pb-4 col-3">
                  <div class="row text-center align-top">
                    <div class="col-12 text-right">
                      <a v-on:click="getExtras(item.id, item.sizes[0].id)" href="#"
                        class="d-inline btn btn-primary btn-sm small m-0 p-0 rounded-circle"
                        style="color:#fff !important;font-family: 'Arial'; line-height: 0px;  font-size: 110% !important;font-weight: bolder; padding: 3px 7px !important;"
                        data-toggle="modal" data-target="#extras">+</a>
                    </div>
                    <div class="col-12 text-center p-2" style="vertical-align: middle;margin-top:-35px;z-index: -1;">
                      <img style="width: 100%;height:100%" alt="#" :src="$store.state.baseURL + '/' + item.image"
                        class="p-1 rounded-circle" />
                    </div>
                  </div>
                  <div class="w-100">
                    <!-- <div v-for="size in item.sizes" v-bind:key="size.id" class="col-4"> -->
                    <div v-if="item.sizes[0] && item.sizes[0].price" class="text-muted mb-0 w-100 text-center">
                      <div style="font-size: 1.2rem;">
                        <div style="font-family: WondarQuason;">{{ item.sizes[0].size_en }}</div>
                        <div style="font-family: Tahoma;color: #663435;">{{ currency + " " + item.sizes[0].price.price
                          }}
                        </div>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!menu" class="p-5 text-center h3 text-primary-dark">
        Loading Menu ...
      </div>
      <!-- Meet our chefs -->
      <div style="
        background-image: url(/img/home/OurChefs.jpg);
        background-position: top right;
        background-size: cover;
        height: 45vw;
        border-top:solid 4px black;
        border-bottom:solid 4px black;">
        <div class="container h-100">
          <div class="row">
            <div class="col pt-3"></div>
          </div>
          <div class="row h-100">

            <div class="col text-left pl-3">
              <div style="text-shadow: black 3px 3px 12px;
                font-family: steelfish;
                font-size: 9.5vw;
                letter-spacing: 1vw;
                font-weight: bold;
                color: rgb(206, 160, 118);
                line-height: normal;
                margin-top: 3vw;">
                MEET OUR</div>
              <div style="font-family: WondarQuason;
              font-size: 9vw;
              color: rgb(206, 160, 118);
              text-shadow: black 3px 3px 12px;
              line-height: normal;
              ">CHEFS</div>
              <div class="text-white" style="font-size: 1.3vw;">Take a look at our chefs and get to know who prepares
                your delicious plates. Take a look at our chefs and get to
                know who prepares your delicious plates.</div>
              <div class="text-right" style="padding-top:3vw;">
                <a class="btn btn-light p-0 px-3" style="color:#663435;border-radius: 30px;font-size:1.4vw;"
                  href="/our_chefs">Our Chefs</a>
              </div>
            </div>
            <div class="col">

            </div>
          </div>
        </div>
      </div>
      <div onclick="document.location='/about_us'" class="home-aboutus-title" style="cursor: pointer;">
        OUR MISSION
      </div>
      <div class="p-4" style="font-size:1.1rem ; font-weight: 300;word-spacing: 2px;">Our mission is to elevate the
        on-the-go dining experience for the discerning our<br>
        clientele by delivering gourmet pasta dishes crafted with premium ingredients and<br>
        unparalleled convenience.
      </div>
      <div style="background-color: #663435; color: #CEA076;">
        <div onclick="document.location='/about_us'" class="home-vision-title" style="cursor: pointer;">
          OUR VISION
        </div>
        <div class="p-4" style="font-size:1.1rem ; font-weight: 300;word-spacing: 2px;">
          Our vision is to become the premier destination for individuals seeking elevated on-the-go<br>
          dining experiences worldwide. We aim to set the benchmark for fast-casual dining at<br>
          the highest echelon.
        </div>
      </div>
      <!-- Kitchen Tour -->
      <div style="
        background-image: url(/img/home/KitchenTour.jpg);
        background-position: top right;
        background-size: cover;
        height: 40vw;
        ">
        <div class="container h-100">
          <div class="row">
            <div class="col pt-3"></div>
          </div>
          <div class="row h-100">

            <div class="col text-left pl-3">
              <div style="
                font-family: steelfish;
                font-size: 7.5vw;
                letter-spacing: 1vw;
                font-weight: bold;
                color: rgb(206, 160, 118);
                line-height: normal;
                margin-left: 7vw;
                margin-top: 4.7vw;">
                KITCHEN</div>
              <div style="font-family: WondarQuason;
              font-size: 8.3vw;
              color: rgb(206, 160, 118);
              line-height: normal;
              margin-left: 7vw;
              ">TOUR</div>
              <div class="text-black text-left" style="font-size: 1.3vw;
                        margin-top: 2vw;
                        margin-left: 7vw;
                        line-height: 25px;">
                Take a look at our chefs and get to know who prepares
                your delicious plates. Take a look at our chefs and get to
                know who prepares your delicious plates.</div>
              <div class="text-right" style="padding-top:3vw;">
                <a class="btn btn-dark p-0 px-3 text-white"
                  style="border-color: #663435; background-color: #663435;border-radius: 30px;font-size:1.4vw;"
                  href="/kitchen_tour">Take Tour</a>
              </div>
            </div>
            <div class="col">

            </div>
          </div>
        </div>
      </div>


      <!-- Add to cart modal -->
      <div class="modal fade" id="extras" tabindex="-1" role="dialog" aria-labelledby="extraModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div v-if="itemToAdd" class="modal-content"
            style="border-radius: 40px !important;background: rgba(255, 255, 255, 0.7);backdrop-filter: blur(16px) brightness(2);">
            <div class="modal-header" style="border: 0 !important;">
              <button type="button" class="close pl-5 mr-1" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body px-5">
              <form>
                <!-- extras body -->
                <div class="recepie-body text-left">
                  <div class="row">
                    <div class="col-md-4 align-top">
                      <img style="width: 100%;max-width: 30vw;margin-bottom: 3vw;" alt="#" :src="$store.state.baseURL + '/' + itemToAdd.image"
                        class="p-1 rounded-circle" />
                    </div>
                    <div class="h6 col-md-8" style="font-family: WondarQuason;">
                      <div class="row">
                        <div class="col-lg-7 p-0 font-weight-bold text-left" style="font-size: 1.5em;">{{
                          itemToAdd.name_en }}</div>
                        <!-- <span class="m-0">{{ countToAdd }} Item</span> -->
                        <div class="col-lg-3 p-0 text-right">
                          <span class="count-number">
                            <button v-on:click="
                              countToAdd > 1 ? countToAdd-- : (countToAdd = 1)
                              " type="button" class="btn-sm left dec btn text-white"
                              style="background-color: #663435;border-color: #663435;border-radius: 50% 0 0 50%;border-right: 0px;">
                              <i class="feather-minus"></i></button>
                            <input style="width: 35px !important;" class="count-number-input text-white" type="text"
                              readonly="" :value="countToAdd" />
                            <button v-on:click="countToAdd++" type="button" class="btn-sm right inc btn  text-white"
                              style="background-color: #663435;border-color: #663435;border-radius: 0 50% 50% 0;border-left: 0px;">
                              <i class="feather-plus"></i></button>
                          </span>
                        </div>
                        <div class="col-lg-2 ml-auto text-right" style="font-family: Tahoma;">{{ currency + " " +
                          sizeToAdd.price.price }}</div>
                      </div>
                      <hr style="border-color:black ;" />
                      <div style="font-size: 1.2rem; font-family: Tahoma;padding-bottom: 4px;">Description</div>
                      <div class="text-mute"
                        style="font-family: Tahoma;font-size: 0.8rem;letter-spacing: 0.5px;color:gray;">{{
                          itemToAdd.description_en }}</div>
                      <div class="row p-3">
                        <h6 class="font-weight-bold mt-4" style="font-family: Tahoma;"><i
                            class="header-icon message-icon"
                            style=" background-color: #000; display: inline-block;"></i> Any special requests?</h6>
                        <div class="w-100 align-items-center">
                          <textarea rows="" class="form-control border border-dark rounded-0" id="special"
                            name="special" v-model="specialToAdd" />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- Options & Extras -->
                <div v-if="sizeToAdd && sizeToAdd.size_condiments" class="text-left">
                  <!-- Options -->
                  <div style="margin-top: 10px"
                    v-show="sizeToAdd.size_condiments.filter((cond) => { return cond.type == 'option' }).length > 0"
                    class="text-primary-dark mt-3 rounded border-bottom">
                    <h6 class="font-weight-bold m-0">Options</h6>
                  </div>
                  <div v-for="option in sizeToAdd.size_condiments.filter((cond) => { return cond.type == 'option' })"
                    v-bind:key="option.id" class="border-bottom py-2">
                    <div class="custom-control custom-radio d-flex" :id="'subDiv' + option.id">
                      <div class="py-1 px-2 mx-2 font-weight-bold" style="text-transform: capitalize;">
                        {{ option.group_name }}
                      </div>
                      <div class="py-1 px-2 mx-2" v-for="subOption in option.condiments" v-bind:key="subOption.id">
                        <input v-on:click="addOption(subOption, option)" type="radio" :id="'subExt' + subOption.id"
                          :name="'subExt' + option.id" class="custom-control-input" :checked="subOption.selected" />
                        <label class="custom-control-label" :for="'subExt' + subOption.id">{{ subOption.name }}
                          <span v-if="subOption.price > 0" class="text-muted">{{
                            subOption.price != 0
                              ? "(+" + subOption.price + " " + currency + ")"
                              : ""
                          }}</span></label>
                      </div>
                    </div>
                  </div>

                  <!-- Extras -->
                  <div style="margin-top: 10px !important"
                    v-show="sizeToAdd.size_condiments.filter((cond) => { return cond.type == 'extra' }).length > 0"
                    class="text-primary-dark mt-3 rounded border-bottom">
                    <h6 class="font-weight-bold m-0 text-white">Extras</h6>
                  </div>
                  <div v-for="extra in sizeToAdd.size_condiments.filter((cond) => { return cond.type == 'extra' })"
                    v-bind:key="extra.id" class="border-bottom py-2">
                    <input type="checkbox" :id="'ex' + extra.id" name="extra" class="custom-control-input" />
                    <div class="py-1 px-2 mx-2 font-weight-bold" style="text-transform: capitalize;">
                      {{ extra.group_name }}
                    </div>
                    <div :id="'opsSubDiv' + extra.id" class="custom-control custom-checkbox">
                      <div class="py-1 px-2 mx-2" v-for="subExtra in extra.data" v-bind:key="subExtra.id">
                        <input v-on:click="addExtra(subExtra)" type="checkbox" :id="'opsSubExt' + subExtra.id"
                          :name="'opsSubExt' + subExtra.id" class="custom-control-input" />
                        <label class="custom-control-label" :for="'opsSubExt' + subExtra.id">{{ subExtra.name }}
                          <span v-if="subOption.price > 0" class="text-muted">{{
                            subExtra.price != 0
                              ? "(+" + subExtra.price + " " + currency + ")"
                              : ""
                          }}</span></label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer p-0 border-0 d-flex justify-content-center">
              <!-- <div class="col-3 m-0 p-0">
                <button type="button" class="btn border-top btn-lg btn-block" data-dismiss="modal">
                  Close
                </button>
              </div> -->
              <div class="col-4  m-0 mb-2 p-0">
                <button type="button" v-on:click="addItem" data-dismiss="modal"
                  class="btn btn-primary text-dark  m-2 btn-block rounded-pill">
                  Add To Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="false" :isLoading="isLoading" />
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
//import { mapMutations } from "vuex";
import homeMenuHeader from "../components/homeMenuHeader.vue";
import CheckoutDetails from "../components/checkoutDetails.vue";
import Loading from "../components/loading.vue";
import HomeOffers from '../components/homeOffers.vue';

export default {
  components: { homeMenuHeader, CheckoutDetails, Loading, HomeOffers },
  data() {
    return {
      offers: null,
      menu: null,
      menu_id: 1,
      price_list: 0,
      branchs: null,
      order: null,
      itemToAdd: null,
      sizeToAdd: null,
      countToAdd: 1,
      specialToAdd: null,
      extrasToAdd: [],
      optionsToAdd: [],
      isLoading: true,
      currency: "",
      branch: null
    };
  },
  computed: {
    ...mapState(["selArea"]),

    curArea() {
      return this.$store.state.selArea;
    }
  },
  methods: {
    getBranch(rest) {
      fetch(this.$store.state.baseURL + "/api/branches/" + rest.id, {
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          if (response.response) {
            this.result = response.data;
            this.branchs = JSON.parse(JSON.stringify(this.result)).branches;
          } else {
            if (response.message) console.log(response.message);
            else if (response.messages) console.log(response.messages);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOffers() {
      fetch(
        this.$store.state.baseURL +
        "/api/offers/" +
        this.menu_id +
        "/" +
        this.price_list,
        {
          method: "GET",
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          this.result = response.data;
          this.offers = JSON.parse(JSON.stringify(this.result)).offers;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRestMenu(rest) {
      fetch(
        this.$store.state.baseURL + "/api/menu/" + this.curArea + "/" + rest.id,
        {
          method: "GET",
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          this.result = response.data;
          this.menu = JSON.parse(JSON.stringify(this.result)).menu;
          this.menu = this.menu[0];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getExtras(itemId, sizeId) {
      this.itemToAdd = null
      this.sizeToAdd = null
      let getExtrasURL = this.$store.state.baseURL + "/api/item/" + itemId + "/" + this.price_list
      //console.log(getExtrasURL)
      fetch(getExtrasURL, { method: "GET" })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            )
          }
        })
        .then((response) => {
          this.result = response
          let item = JSON.parse(JSON.stringify(this.result))
          this.itemToAdd = item
          this.sizeToAdd = item.sizes.find((c) => c.id == sizeId)

        })
        .catch((err) => {
          console.log(err)
        });
    },
    addOption(subOption, option) {
      for (let i = 0; i < option.condiments.length; i++) {
        const el = option.condiments[i]
        el.selected = false
      }

      Vue.set(subOption, "selected", true)


    },
    addExtra(subExt) {
      if (!subExt.selected) Vue.set(subExt, "selected", true)
      else Vue.set(subExt, "selected", false)


    },
    addItem() {
      let vv = [];
      let optionsVal = 0.0;
      let extrasVal = 0.0;

      if (this.sizeToAdd.size_condiments) {

        let opts = this.sizeToAdd.size_condiments.filter((cond) => { return cond.type == 'option' })
        for (let index = 0; index < opts.length; index++) {
          const element = opts[index];
          for (let i = 0; i < element.condiments.length; i++) {
            const el = element.condiments[i];
            if (el.selected) {
              vv.push(el);
            }
          }
        }

        this.optionsToAdd = vv;
        if (this.optionsToAdd) {
          for (let i = 0; i < this.optionsToAdd.length; i++) {
            const element = this.optionsToAdd[i];
            optionsVal = optionsVal + parseFloat(element.price);
          }
        }
        vv = [];
        let exts = this.sizeToAdd.size_condiments.filter((cond) => { return cond.type == 'extra' })
        for (let index = 0; index < exts.length; index++) {
          const element = exts[index];
          for (let i = 0; i < element.condiments.length; i++) {
            const el = element.condiments[i];
            if (el.selected) {
              vv.push(el);
            }
          }
        }
        this.extrasToAdd = vv;
        if (this.extrasToAdd) {
          for (let i = 0; i < this.extrasToAdd.length; i++) {
            const element = this.extrasToAdd[i];
            extrasVal = extrasVal + parseFloat(element.price);
          }
        }
      }
      this.order.items.push({
        id: this.order.items.length + 1,
        name_en: this.itemToAdd.name_en,
        size_en: this.sizeToAdd.size_en,
        count: this.countToAdd,
        price: this.sizeToAdd.price.price,
        total: extrasVal + optionsVal + this.sizeToAdd.price.price,
        menu_item_id: this.itemToAdd.id,
        options: this.optionsToAdd,
        extras: this.extrasToAdd,
        special: this.specialToAdd,
      });
      this.$store.commit("setCurOrder", this.order);
      try {
        document.getElementsByClassName("modal-backdrop")[0].remove()
        document.getElementsByTagName("body")[0].setAttribute("class", "fixed-bottom-bar")
        document.getElementById("extras").setAttribute("style", "display:none;")
        // .setAttribute("class", "modal-backdrop fade")
      } catch { }
      //console.log(JSON.stringify(this.order.items));
      this.countToAdd = 1;
      this.itemToAdd = null;
      this.sizeToAdd = null;
      this.extrasToAdd = null;
      this.optionsToAdd = null;
      this.specialToAdd = "";
    },
    getCurOrder(menu) {
      let curOrder = JSON.parse(window.localStorage.getItem("curOrder"))
      // console.log(curOrder)
      if (curOrder && curOrder != "") {
        let infos = [];
        menu.sections.forEach((sec) => {
          sec.items.forEach((i) => {
            if (i.sizes) {
              i.sizes.forEach((inf) => {
                infos.push(inf);
              });
            }
          });
        });

        if (infos.length > 0) {
          let verfiyOrder = curOrder
          let order = curOrder;
          order.items = [];

          verfiyOrder.items.forEach((item) => {
            let info = infos.find((i) => i.item_code == item.menu_item_id);
            //console.log(info.id)

            if (!info) {
              alert(
                "Item (" + item.name_en + ") was not found and will be removed"
              );
            } else {
              let exTotal = item.extras
                ? item.extras.reduce((total, obj) => {
                  return total + parseFloat(obj.price_en);
                }, 0)
                : 0;
              let optTotal = item.options
                ? item.options.reduce((total, obj) => {
                  return total + parseFloat(obj.price_en);
                }, 0)
                : 0;
              order.items.push({
                id: order.items.length + 1,
                name_en: item.name_en,
                size_en: item.size_en,
                count: item.count,
                price: info.price.price,
                total:
                  Math.round(exTotal * 100) / 100 +
                  Math.round(optTotal * 100) / 100 +
                  info.price.price,
                menu_item_id: info.item_code,
                options: item.options,
                extras: item.extras,
                special: item.special,
              });
            }
          });

          this.$store.dispatch('UpdateCurrentOrder')
          this.order = order;
        }
      } else {
        this.order = {
          items: [],
          notes: null,
          address: null,
        };
      }
    },
  },
  watch: {
    "$store.state.restaurant"(val) {
      if (val) {
        this.getRestMenu(val);
        this.getBranch(val);
        this.price_list = val.price_list;
      }
    }
    , branchs(val) {
      if (val) {
        var branchs = this.branchs;
        var notnull = branchs.filter((f) => f.area != null);
        this.branch = notnull.find((c) => c.area.area_id == this.curArea);
        window.localStorage.setItem("branch", JSON.stringify(this.branch));

      }
    }
    ,
    menu(val) {
      if (val) {
        this.getOffers();
        this.getCurOrder(val);
      }
    },
    sizeToAdd() {
      if (this.sizeToAdd && this.sizeToAdd.size_condiments) {
        let opts = this.sizeToAdd.size_condiments.filter((cond) => { return cond.type == 'option' })
        for (let index = 0; index < opts.length; index++) {
          const element = opts[index];
          for (let i = 0; i < element.condiments.length; i++) {
            const el = element.condiments[i];
            Vue.set(el, "selected", false);
          }
          element.condiments[0].selected = true;
        }
        let extras = this.sizeToAdd.size_condiments.filter((cond) => { return cond.type == 'extra' })
        for (let index = 0; index < extras.length; index++) {
          const element = extras[index];
          for (let i = 0; i < element.condiments.length; i++) {
            const el = element.condiments[i];
            Vue.set(el, "selected", false);
          }
        }
      }
    },
    // itemToAdd() {
    //   if (this.itemToAdd && this.itemToAdd.item_extras) {
    //     let opts = this.itemToAdd.item_extras;
    //     for (let index = 0; index < opts.length; index++) {
    //       const element = opts[index];
    //       for (let i = 0; i < element.data.length; i++) {
    //         const el = element.data[i];
    //         Vue.set(el, "selected", false);
    //       }
    //     }
    //   }
    // },
  },
  beforeUpdate() { },
  mounted() {
    this.currency = window.currency
    if (
      this.$route.params.Room != undefined ||
      this.$route.params.Table != undefined
    ) {
      let id = 0;
      let _token =
        "KxspuohBUGos0TPHBoA0kEG6Tl8DXr9RcmQhJYTj9phYhDBjWS9edKmYXuxGtnWH";
      let tempUser = {
        user: {
          id: id,
          user_name: "Inside Guest ",
          email: "insideguest@tanoak.com",
          phone: "00000000000",
          auth: "user",
          branches: null,
        },
        userData: {
          user_id: id,
          lookup_id: id,
          name: "Inside Guest ",
          avatar: "default.png",
          gender: "male",
          birth_day: "0000-00-00",
          points: "0",
          coins: 0,
          device_type: "android",
          user_token: _token,
          device_active_token: null,
          created_by: 0,
          updated_by: null,
          deleted_by: null,
          suspend: 0,
          suspend_by: null,
          referrer_code: null,
          confirmation_code: null,
          email_confirmed: 0,
          mobile_confirmed: 0,
          created_at: "2022-07-30 17:36:44",
          updated_at: "2022-07-30 21:48:39",
          deleted_at: null,
        },
        password: true,
        favourites: [],
        address: [],
        token: _token,
      };
      let savedUser = JSON.parse(window.localStorage.getItem("curUser"));
      //console.log(savedUser);
      if (savedUser != null && savedUser.user.id == 0) {
        window.localStorage.removeItem("curUser");
      }
      this.$store.commit("setUser", tempUser);
      window.localStorage.setItem("jwt", tempUser.token);
      window.localStorage.setItem("curUser", JSON.stringify(tempUser));
    }
    this.$store.dispatch("getSelArea");
    //this.getRestMenu();
  },
  updated() {
    this.isLoading = false;
    // let co = JSON.parse(window.localStorage.getItem("curOrder"));
    // if (co) {
    //   if (co.items.length > 0) {
    //     const scel = this.$refs.scrollToMe;
    //     if (scel) {
    //       //scel.scrollIntoView({ behavior: "smooth" });
    //     }
    //   }
    // }
  },
};
</script>
<style>
.myres-menu-item>div:first-child>div {
  border-top: 0px transparent solid !important;
}
</style>